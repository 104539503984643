import React from 'react';
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActualiteAdmin = ({ actu }) => {

    const dateCreate = new Date(actu.createdAt);
    const frenchFormattedDateCreate = dateCreate.toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    const deleteActu = async (actu) => {
        // Afficher une boîte de dialogue de confirmation en français
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette actualité ?");
    
        // Si l'utilisateur confirme, continuer avec la suppression
        if (isConfirmed) {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/actualites/${actu._id}`);
            window.location.reload();
        }
    }

    const cleanDescription = (html) => {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    const shortDescription = cleanDescription(actu.description).substring(0, 60);

    return (
        <tr key={actu._id} className="table-tr">
            <td>{frenchFormattedDateCreate}</td>
            <td>{actu.title}</td>
            <td>{shortDescription}...</td>
            <td>
                <img
                    src={actu.image}
                    alt={actu.title}
                    style={{ maxWidth: '80px', maxHeight: '80px' }}
                />
            </td>
            <td style={{ cursor: 'pointer' }}><FontAwesomeIcon
                icon={faTrash}
                size={80}
                className="three-dots-icons"
                onClick={() => deleteActu(actu)}
            /></td>
        </tr>
    );
};

export default ActualiteAdmin;