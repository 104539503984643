import React from 'react';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserAdmin = ({user}) => {

    const deleteUser = async (user) => {
        // Afficher une boîte de dialogue de confirmation en français
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
    
        // Continuer avec la suppression seulement si l'utilisateur confirme
        if (isConfirmed) {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/users/${user._id}`);
            window.location.reload();
        }
    }

    return (
        <tr key={user._id} className="table-tr">
            <td>{user.lastName}</td>
            <td>{user.firstName}</td>
            <td>{user.phone}</td>
            <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
            <td style={{cursor: 'pointer'}}><FontAwesomeIcon
                icon={faTrash}
                size={80}
                className="three-dots-icons"
                onClick={() => deleteUser(user)}
            /></td>
        </tr>
    );
};

export default UserAdmin;