import React, { useState } from 'react';
import { storage } from "../firebase";

const SingleImageUpload = ({ setDownloadUrl, setIsUploading  }) => {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const uploadFile = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            return;
        }

        // Check file size
        if (selectedFile.size > 819200) {
            alert('La taille du fichier dépasse 800 Ko. Veuillez sélectionner un fichier plus petit.');
            return; // Prevent the upload
        }

        setFile(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile));
        uploadToFirebase(selectedFile);
    };

    const uploadToFirebase = (selectedFile) => {
        const randomNum = Math.random() * 10000;
        const uploadTask = storage.ref(`commerces/${selectedFile.name}${randomNum}`).put(selectedFile);
        setIsUploading(true);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                console.log(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref("commerces")
                    .child(`${selectedFile.name}${randomNum}`)
                    .getDownloadURL()
                    .then(url => {
                        setDownloadUrl(url);
                        setIsUploading(false);
                        console.log('url', url);
                    });
            }
        );
    };

    return (
        <form className="my-5">
            {previewUrl && (
                <div className="form-group multi-preview">
                    <img src={previewUrl} alt="Preview" style={{width: 200, height: 200}} />
                </div>
            )}

            <div className="form-group">
                <input type="file" className="form-control" onChange={uploadFile} accept="image/png, image/jpeg, image/jpg, image/webp" />
            </div>
        </form>
    );
};

export default SingleImageUpload;
