import React, { useState } from 'react';
import { storage } from "../firebase";
import axios from 'axios';

const ImageChangeCustom = ({ image, promoImages, index, setPromoImages, commerceId }) => {

    let imageCurr;

    const [previewImage, setPreviewImage] = useState("");

    const handleChange = e => {
        const randomNum = Math.random() * 10000;
        if (e.target.files[0]) {
            const imageFile = e.target.files[0];

            // Vérifier si la taille du fichier dépasse 800 Ko (819200 octets)
            if (imageFile.size > 819200) {
                alert('La taille du fichier dépasse 800 Ko. Veuillez sélectionner un fichier plus petit.');
                return; // Quitter la fonction si le fichier est trop grand
            }

            imageCurr = imageFile;
            setPreviewImage(URL.createObjectURL(imageFile));

            setTimeout(() => {
                const uploadTask = storage.ref(`images/${imageCurr.name}${randomNum}`).put(imageCurr);
                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                    },
                    error => {
                        console.log(error);
                    },
                    () => {
                        storage
                            .ref("images")
                            .child(`${imageCurr.name}${randomNum}`)
                            .getDownloadURL()
                            .then(async url => {
                                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/commerces/back-office/image`, {
                                    id: commerceId,
                                    image: url,
                                    index
                                });
                            });
                    });
            }, 1000);
        }
    };

    return (
        <div>
            <img src={previewImage || image} alt="" width={250} height={250} />
            <div>
                <label className="btn btn-default btn-danger">
                    Modifier <input type="file" style={{ display: "none" }} required onChange={(e) => handleChange(e)} accept="image/png, image/jpeg, image/jpg, image/webp" />
                </label>

            </div>
        </div>
    );
};

export default ImageChangeCustom;