import React from 'react';
import { Navigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

export const isValidToken = (encryptedToken) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, "m%$dF7@fM%");
        const token = bytes.toString(CryptoJS.enc.Utf8);
        const [login, timestamp] = token.split('-');

        // Check if the token is expired, e.g., 24-hour expiry
        return new Date().getTime() - parseInt(timestamp, 10) < 72 * 60 * 60 * 1000;
    } catch (e) {
        return false; // Token is invalid
    }
};

export const RequireAuth = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.token || !isValidToken(user.token)) {
        return <Navigate to="/" />;
    }

    return children;
};