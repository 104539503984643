import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user info from local storage
        localStorage.removeItem('user');

        // Navigate back to the login page
        navigate('/');
    };

    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/list-actu">Appli CSE</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    {user.role === 'superadmin' && (
                        <li className="nav-item active">
                            <a className="nav-link" href="/list-commerces">Liste des commerces</a>
                        </li>
                    )}
                    {user.role === 'superadmin' && (
                        <li className="nav-item">
                            <a className="nav-link" href="/create-commerce">Créer un commerce</a>
                        </li>
                    )}
                    <li className="nav-item">
                        <a className="nav-link" href="/list-users">Liste des utilisateurs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/list-actu">Liste des actualités</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/create-actu">Créer une actualité</a>
                    </li>
                    <li className="nav-item">
                        <button onClick={handleLogout} className="nav-link btn btn-link">Se déconnecter</button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavBar;