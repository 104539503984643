import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import NavBar from "./components/NavBar";
import CommerceAdmin from "./components/admin/CommerceAdmin";
import UserAdmin from "./components/admin/UserAdmin";


const ListUsersScreen = () => {
    const [users, setUsers] = useState([]);
    const [showImported, setShowImported] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users`);
            console.log('link', `${process.env.REACT_APP_BASE_URL}/api/users`)
            console.log('data', data)
            setUsers(data);
        }
        getUsers()
    }, []);

    console.log('users', users);

    let filteredData = users.filter(user => showImported || !user.isImported);
    const [searchTerm, setSearchTerm] = useState("");
    const [nameFiltered, setNameFiltered] = useState("");

    if (nameFiltered) {
        filteredData = users.filter(
            (dt) => {
                if (nameFiltered === "") {
                    return dt
                } else if (dt.lastName.toLowerCase().includes(nameFiltered.toLowerCase())) {
                    return dt
                }
            }
        );
    }

    return (
        <div className="container align-items-center">
            <NavBar/>
            <button className="btn btn-primary my-3" onClick={() => navigate('/import-users')}>Importer</button>
            <button className="btn btn-secondary my-3 mx-2" onClick={() => setShowImported(!showImported)}>Voir tout</button>
            <table className="table">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Téléphone</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {filteredData?.map((commerce, index) => {
                        return (
                            <UserAdmin user={commerce} key={index}/>
                        )
                    }
                )}
                </tbody>
            </table>
        </div>
    );
};

export default ListUsersScreen;