import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";

const ModifyHoraires = ({
                            day,
                            value1,
                            value2,
                            value3,
                            value4,
                            onChange1,
                            onChange2,
                            onChange3,
                            onChange4,
                            condition,
                            valueCheckbox,
                            onChangeCheckbox
}) => {


    return (
        <div>
            <p className="mb-0">{day}</p>

            <FormControlLabel control={<Checkbox label="Fermé" checked={valueCheckbox} onChange={onChangeCheckbox}/>} label="Fermé" className="mb-2"/>
            {condition ?     <div style={{display: 'flex'}} className="mb-2">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Horaire d'ouverture Matin (08:15)"
                    value={value1}
                    onChange={onChange1}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Horaire de fermeture Matin (17:45)"
                    value={value2}
                    onChange={onChange2}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Horaire d'ouverture Après-midi (08:15)"
                    value={value3}
                    onChange={onChange3}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Horaire de fermeture Après-midi (17:45)"
                    value={value4}
                    onChange={onChange4}
                />
            </div> :  <div/>}
        </div>
    );
};

export default ModifyHoraires;