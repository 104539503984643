import React, { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreateCommerceScreen from "./CreateCommerceScreen";
import LoginScreen from "./LoginScreen";
import ListCommercesScreen from "./ListCommercesScreen";
import CommerceDetailScreen from "./CommerceDetailScreen";
import ListUsersScreen from "./ListUsersScreen";
import CreateActualiteScreen from "./CreateActualite";
import ImportUsers from "./ImportUsers";
import { RequireAuth } from "./middleware/RequireAuth";
import ListActualitesScreen from "./ListActualites";


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginScreen />} />
                <Route path="/create-commerce" element={
                    <RequireAuth>
                        <CreateCommerceScreen />
                    </RequireAuth>
                } />
                <Route path="/list-commerces" element={
                    <RequireAuth>
                        <ListCommercesScreen />
                    </RequireAuth>
                } />
                <Route path="/commerce-detail/:id" element={
                    <RequireAuth>
                        <CommerceDetailScreen />
                    </RequireAuth>
                } />
                <Route path="/list-users" element={
                    <RequireAuth>
                        <ListUsersScreen />
                    </RequireAuth>
                } />
                <Route path="/list-actu" element={
                    <RequireAuth>
                        <ListActualitesScreen />
                    </RequireAuth>
                } />
                <Route path="/create-actu" element={
                    <RequireAuth>
                        <CreateActualiteScreen />
                    </RequireAuth>
                } />
                <Route path="/import-users" element={
                    <RequireAuth>
                        <ImportUsers />
                    </RequireAuth>
                } />
            </Routes>
        </Router>
    )
}

export default App;
