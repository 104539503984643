import React from 'react';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CommerceAdmin = ({user}) => {

    const deleteCommerce = async (user) => {
        // Afficher une boîte de dialogue de confirmation en français
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce commerce ?");
    
        // Continuer avec la suppression seulement si l'utilisateur confirme
        if (isConfirmed) {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/commerces/${user._id}`);
            window.location.reload();
        }
    }
    const navigate = useNavigate();

    const handleNavigate = async (user) => {
        navigate(`/commerce-detail/${user._id}`, {
            state: {
                commerce: user,
            }
        });
    }

    return (
            <tr key={user._id} className="table-tr">
                <td>{user.name}</td>
                <td>{user.type}</td>
                <td>{user?.category}</td>
                <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                <td>{user.address}</td>
                <td>{user?.phone}</td>
                <td>{user?.website}</td>
                <td>{user?.subscriptionPack}</td>
                <td>{user?.subscriptionDate?.substring(0,10)}</td>
                <td style={{cursor: 'pointer'}}><FontAwesomeIcon
                    icon={faPenToSquare}
                    size={80}
                    className="three-dots-icons"
                    onClick={() => handleNavigate(user)}
                /></td>
                <td style={{cursor: 'pointer'}}><FontAwesomeIcon
                    icon={faTrash}
                    size={80}
                    className="three-dots-icons"
                    onClick={() => deleteCommerce(user)}
                /></td>
            </tr>
    );
};

export default CommerceAdmin;