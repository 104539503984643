import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavBar from "./components/NavBar";
import { Table } from 'react-bootstrap'
import CommerceAdmin from "./components/admin/CommerceAdmin";

const ListCommercesScreen = () => {

    const [commerces, setCommerces] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        //Redirect if not super admin
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.role != 'superadmin')
            navigate('/list-actu');
        const getCommerces = async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/commerces`);
            console.log(data)
            setCommerces(data);
        }
        getCommerces()
    }, []);

    console.log('commerces', commerces);

    let filteredData = commerces
    const [searchTerm, setSearchTerm] = useState("");
    const [nameFiltered, setNameFiltered] = useState("");

    if (nameFiltered) {
        filteredData = commerces.filter(
            (dt) => {
                if (nameFiltered === "") {
                    return dt
                } else if (dt.name.toLowerCase().includes(nameFiltered.toLowerCase())) {
                    return dt
                }
            }
        );
    }

    return (
        <div className="container align-items-center">
            <NavBar />
            {commerces ? <table className="table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Type</th>
                        <th>Sous-type</th>
                        <th>Contact</th>
                        <th>Adresse</th>
                        <th>Téléphone</th>
                        <th>Site web</th>
                        <th>Pack Souscrit</th>
                        <th>Date de souscription</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((commerce, index) => {
                        return (
                            <CommerceAdmin user={commerce} key={index} />
                        )
                    }
                    )}
                </tbody>
            </table> : <div />}

        </div>
    );
};

export default ListCommercesScreen;
