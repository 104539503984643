import React, { useState, useEffect } from 'react';
import { storage } from "../firebase";

const MultipleImagesUpload = ({ setDownloadUrls }) => {


    const [fileObj, setFileObj] = useState([])
    const [fileArray, setFileArray] = useState([])
    const [blobArray, setBlobArray] = useState([]);



    const uploadMultipleFiles = (e) => {
        const files = Array.from(e.target.files);
        const filteredFiles = files.filter(file => file.size <= 819200);

        if (filteredFiles.length !== files.length) {
            alert('Certains fichiers ont été exclus car ils dépassent 800 Ko.');
        }

        setFileObj([...filteredFiles]);
        const blobUrls = filteredFiles.map(file => URL.createObjectURL(file));
        setBlobArray(blobUrls);

        const randomNum = Math.random() * 10000;
        let tempUrls = [];

        filteredFiles.forEach(document => {
            const uploadTask = storage.ref(`commerces/${document.name}${randomNum}`).put(document);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    console.log(progress);
                },
                error => {
                    console.log(error);
                },
                () => {
                    storage
                        .ref("commerces")
                        .child(`${document.name}${randomNum}`)
                        .getDownloadURL()
                        .then(url => {
                            tempUrls.push(url);
                            console.log('url', url);
                        });
                }
            );
        });
        setDownloadUrls(tempUrls);
    };

    return (
        <form className="my-5">
            <div className="form-group multi-preview">
                {(blobArray || []).map(url => (
                    <img src={url} alt="..." style={{ width: 200, height: 200 }} />
                ))}
            </div>

            <div className="form-group">
                <input type="file" className="form-control" onChange={uploadMultipleFiles} multiple accept="image/png, image/jpeg, image/jpg, image/webp" />
            </div>

        </form>
    );
};

export default MultipleImagesUpload;
