import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';
import { isValidToken } from './middleware/RequireAuth';

const LoginScreen = () => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const users = {
        adminCSE: { passwordHash: bcrypt.hashSync("K9$Fd9@", 10), role: "user" },
        admin: { passwordHash: bcrypt.hashSync("s@R%L10$", 10), role: "superadmin" }
    };

    const generateToken = (login) => {
        const timestamp = new Date().getTime();
        const baseToken = `${login}-${timestamp}`;
        // Encrypt the token
        return CryptoJS.AES.encrypt(baseToken, "m%$dF7@fM%").toString();
    };

    const navigate = useNavigate()
    const handleSubmit = () => {
        const user = users[login];
        if (user && bcrypt.compareSync(password, user.passwordHash)) {
            const token = generateToken();
            localStorage.setItem('user', JSON.stringify({ login, role: user.role, token }));
            navigate('/create-actu');
        } else {
            alert('Mauvais login ou mot de passe');
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token && isValidToken(user.token)) {
            navigate('/list-commerces');
        }
    }, [navigate]);

    return (
        <div>
            <h5 style={{ textAlign: 'center' }}>Formulaire de connection</h5>
            <form action="" className='connection-form'>
                <div>
                    <div>
                        <label htmlFor="login" className="form-label">Login</label>
                    </div>
                    <input type="text" id="login" value={login} className="form-control" onChange={(e) => setLogin(e.target.value)} />
                </div>

                <div>
                    <div>
                        <label htmlFor="password" className="form-label">Mot de passe</label>
                    </div>

                    <input type="password" id="password" value={password} className="form-control" onChange={(e) => setPassword(e.target.value)} />
                </div>

                <button onClick={handleSubmit} type="button" className="btn btn-primary my-3">Se connecter</button>
            </form>
        </div>
    );
};

export default LoginScreen;
