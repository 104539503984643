// Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_Uk1o6--aPTmXioj-pOrzDCz1JrmUmXw",
    authDomain: "appicity-7fe24.firebaseapp.com",
    projectId: "appicity-7fe24",
    storageBucket: "appicity-7fe24.appspot.com",
    messagingSenderId: "700314480280",
    appId: "1:700314480280:web:8e621d9a31531f874baaed",
    measurementId: "G-5RSYCFNQ04"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();


export { storage, firebase as default };
