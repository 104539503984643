const sendNotification = (notifTitle, notifDescription, notifImage, notifParams, notifScreen) => {
  const stripHtml = (htmlString) => {
    let text = htmlString.replace(/<\/div>/gi, "\n"); // Replace </div> with \n
    text = text.replace(/&nbsp;/gi, " ");
    return text.replace(/<\/?[^>]+(>|$)/g, "");      // Remove remaining HTML tags
  };

  // Clean the notifDescription from HTML tags and replace </div> with \n
  const cleanNotifDescription = stripHtml(notifDescription);

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Basic ${process.env.REACT_APP_ONESIGNAL_API_KEY}`,
  };
  const endpoint = 'https://onesignal.com/api/v1/notifications';
  const params = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      included_segments: ['Total Subscriptions'],
      app_id: process.env.REACT_APP_ONESIGNAL_APP_ID,
      contents: { en: cleanNotifDescription },
      headings: { en: notifTitle },
      ios_attachments: { id: notifImage },
      big_picture: notifImage,
      data: { screen: notifScreen, params: notifParams },
    }),
  };
  fetch(endpoint, params)
    .then(response => response.json())
    .then(data => {
      console.log('One Signal response:', data);
    })
    .catch(error => {
      console.error('Error sending notification:', error);
    });
};

export default sendNotification;
