import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NavBar from "./components/NavBar";
import ImageChangeCustom from "./components/ImageChangeCustom";
import ModifyHoraires from "./components/ModifyHoraires";

const CommerceDetailScreen = ({ location }) => {

    const { state } = useLocation();

    const { commerce } = state || {};

    console.log('search', commerce)
    console.log(';dfjdfjds');
    const [commerceData, setCommerceData] = useState({
        id: commerce._id,
        name: commerce.name,
        phone: commerce.phone || '',
        email: commerce.email || '',
        password: commerce.password || '',
        notifs: commerce.notifications || '',
        startTimeMonMor: commerce.startTimeMonMor || '',
        endTimeMonMor: commerce.endTimeMonMor || '',
        startTimeTueMor: commerce.startTimeTueMor || '',
        endTimeTueMor: commerce.endTimeTueMor || '',
        startTimeWedMor: commerce.startTimeWedMor || '',
        endTimeWedMor: commerce.endTimeWedMor || '',
        startTimeThuMor: commerce.startTimeThuMor || '',
        endTimeThuMor: commerce.endTimeThuMor || '',
        startTimeFriMor: commerce.startTimeFriMor || '',
        endTimeFriMor: commerce.endTimeFriMor || '',
        startTimeSatMor: commerce.startTimeSatMor || '',
        endTimeSatMor: commerce.endTimeSatMor || '',
        startTimeSunMor: commerce.startTimeSunMor || '',
        endTimeSunMor: commerce.endTimeSunMor || '',
        startTimeMonAft: commerce.startTimeMonAft || '',
        endTimeMonAft: commerce.endTimeMonAft || '',
        startTimeTueAft: commerce.startTimeTueAft || '',
        endTimeTueAft: commerce.endTimeTueAft || '',
        startTimeWedAft: commerce.startTimeWedAft || '',
        endTimeWedAft: commerce.endTimeWedAft || '',
        startTimeThuAft: commerce.startTimeThuAft || '',
        endTimeThuAft: commerce.endTimeThuAft || '',
        startTimeFriAft: commerce.startTimeFriAft || '',
        endTimeFriAft: commerce.endTimeFriAft || '',
        startTimeSatAft: commerce.startTimeSatAft || '',
        endTimeSatAft: commerce.endTimeSatAft || '',
        startTimeSunAft: commerce.startTimeSunAft || '',
        endTimeSunAft: commerce.endTimeSunAft || '',
        type: commerce.type || '',
        category: commerce.categorie || '',
        website: commerce.website || '',
        description: commerce.description || '',
        facebook: commerce.facebook || '',
        instagram: commerce.instagram || '',
        twitter: commerce.twitter || ''
    });

    const [address, setAddress] = useState(commerce.address);
    const [image, setImage] = useState(commerce.image);
    const [password, setPassword] = useState("");
    const [previousPassword, setPreviousPassword] = useState(commerce.password);

    const [coordinates, setCoordinates] = useState({
        lat: commerce.latlng.latitude,
        lng: commerce.latlng.longitude
    });

    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value);
        const latLng = await getLatLng(result[0]);
        setAddress(value);
        setCoordinates(latLng)
        console.log("address", value)
    }

    const [commerceType, setCommerceType] = useState(commerceData.type);
    const [displayImage, setDisplayImage] = useState(commerceData.displayImage);

    console.log(commerceData)
    console.log(commerceData.category)
    const hashMap = {
        1: { type: 'Alimentation', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAlimentation.png?alt=media&token=7411454b-6826-4e20-87b4-cef2cab959fa' },
        2: { type: 'Animations', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAnimations.png?alt=media&token=c13708b5-96cf-4277-ae5a-b14802f592ed' },
        3: { type: 'Animaux', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAnimaux.png?alt=media&token=5ab251cf-5120-41e1-9da5-febb61f1a76a' },
        4: { type: 'Banque Assurance', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FBanque_Assurance.png?alt=media&token=9811c6d4-d830-4ab0-8920-a1761c05c998' },
        5: { type: 'Bijouterie Horlogerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FBijouterie_Horlogerie.png?alt=media&token=cb937811-2479-4013-a8e3-767339d29e57' },
        6: { type: 'Caviste et Epicerie fine', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCaviste_et_Epicerie_fine.png?alt=media&token=aec29e78-c4f9-42a7-8071-88746027add8' },
        7: { type: 'Chaussures', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FChaussures.png?alt=media&token=20050c5f-be09-45bf-b6ed-d7b7060a8997' },
        8: { type: 'Chocolatier', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FChocolatier.png?alt=media&token=94659ce0-8f85-4b42-847d-4c3d6d1de12c' },
        9: { type: 'Coiffure', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCoiffure.png?alt=media&token=74443d69-c465-41fc-b52b-2b233de012b0' },
        10: { type: 'Contrôle technique', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FControle_technique.png?alt=media&token=c3c13953-2520-4979-a8a9-303349a915fe' },
        11: { type: 'Cuisines', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCuisines.png?alt=media&token=708746fc-4b33-4e17-8234-c329c44de938' },
        12: { type: 'Cycles', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCycles.png?alt=media&token=ebcc7e66-d44d-46f3-9046-b7d43b16cca6' },
        13: { type: 'Enfants', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FEnfants.png?alt=media&token=08d78790-3023-4f6d-9352-f48ccc71f950' },
        14: { type: 'Fleurs', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FFleurs.png?alt=media&token=3b4aeabd-b83c-432d-b11c-730a06ae4daa' },
        15: { type: 'Garage', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FGarage.png?alt=media&token=354c9849-8ed6-4945-b6cd-f3d3c5ccb0bf' },
        16: { type: 'Gites et chambres d\'hotes', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FGites_et_chambres_dhotes.png?alt=media&token=51230525-e1b5-4c95-8395-50e516828983' },
        17: { type: 'Informatique', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FInformatique.png?alt=media&token=12127f43-ead3-44eb-892d-4c403e402be3' },
        18: { type: 'Librairie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLibrairie.png?alt=media&token=f0a516bf-e102-40e7-a3af-5238821609da' },
        19: { type: 'Lingerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLingerie.png?alt=media&token=6d98941d-f63b-40e8-b43b-7c4483432497' },
        20: { type: 'Location de véhicules', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLocation_de_vehicules.png?alt=media&token=0261ad53-26b3-4326-8785-095924d58532' },
        21: { type: 'Loisirs', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLoisirs.png?alt=media&token=a87e2e3a-cf0a-4352-b832-c1b0e6c3cff5' },
        22: { type: 'Magasin de jouets', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMagasin_de_jouets.png?alt=media&token=1195773c-0d23-44b5-a620-d808ac3675bd' },
        23: { type: 'Magasin de sport', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMagasin_de_sport.png?alt=media&token=9011039c-285d-440a-8664-a8d3dfe2eba4' },
        24: { type: 'Maison et cadeaux', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaison_et_cadeaux.png?alt=media&token=736b8590-24f7-4ffa-931f-1f2a42b0f309' },
        25: { type: 'Maison et services', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaison_et_services.png?alt=media&token=7b8ae80b-4101-477f-9aed-925e8a73e550' },
        26: { type: 'Mariage et évènements', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMariage_et_evenements.png?alt=media&token=0bb6e89d-fef0-4495-b6a7-6849b4d1b057' },
        27: { type: 'Maroquinerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaroquinerie.png?alt=media&token=3629b632-3483-43d9-a35f-38800c830b51' },
        28: { type: 'Médical', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMedical.png?alt=media&token=40f68095-91b4-44df-b03d-2f50e70841aa' },
        29: { type: 'Opticien', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FOpticien.png?alt=media&token=b1646975-7af8-4fc3-8cf0-2e70921c2b87' },
        30: { type: 'Papeterie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FPapeterie.png?alt=media&token=b54b02b2-15a6-417d-89a8-33b23523e854' },
        31: { type: 'Parfumerie et Institut de beauté', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FParfumerie_et_Institut_de_beaute.png?alt=media&token=29ca8018-586d-4527-9a64-303192f4d008' },
        32: { type: 'Pharmacie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FPharmacie.png?alt=media&token=58a6f376-c0e8-46fe-9634-d00deb78530f' },
        33: { type: 'Restaurant', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FRestaurant.png?alt=media&token=9768f105-ed52-4e05-8108-f9f2b31f0c1f' },
        34: { type: 'Soin beauté et Minceur', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FSoin_beaute_et_Minceur.png?alt=media&token=dc3299b1-7865-4ddb-8ed2-85b883653780' },
        35: { type: 'Sport', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FSport.png?alt=media&token=6f72643d-f73d-4cef-87f0-d88c02026c96' },
        36: { type: 'Tatouage', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FTatouage.png?alt=media&token=632183f1-b415-4faa-903f-6b84e41dc7f3' },
        37: { type: 'Vêtements', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FV%C3%AAtements.png?alt=media&token=0e729e68-ec6a-4905-a49a-a11a339831be' }
    };

    const navigate = useNavigate();


    const handleUpload = async () => {
        if (previousPassword === commerce.password) {
            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/commerces/back-office`, {
                    id: commerceData.id,
                    address: address,
                    name: commerceData.name,
                    startTimeMonMor: commerceData.startTimeMonMor,
                    startTimeTueMor: commerceData.startTimeTueMor,
                    startTimeWedMor: commerceData.startTimeWedMor,
                    startTimeThuMor: commerceData.startTimeThuMor,
                    startTimeFriMor: commerceData.startTimeFriMor,
                    startTimeSatMor: commerceData.startTimeSatMor,
                    startTimeSunMor: commerceData.startTimeSunMor,
                    endTimeMonMor: commerceData.endTimeMonMor,
                    endTimeTueMor: commerceData.endTimeTueMor,
                    endTimeWedMor: commerceData.endTimeWedMor,
                    endTimeThuMor: commerceData.endTimeThuMor,
                    endTimeFriMor: commerceData.endTimeFriMor,
                    endTimeSatMor: commerceData.endTimeSatMor,
                    endTimeSunMor: commerceData.endTimeSunMor,
                    startTimeMonAft: commerceData.startTimeMonAft,
                    startTimeTueAft: commerceData.startTimeTueAft,
                    startTimeWedAft: commerceData.startTimeWedAft,
                    startTimeThuAft: commerceData.startTimeThuAft,
                    startTimeFriAft: commerceData.startTimeFriAft,
                    startTimeSatAft: commerceData.startTimeSatAft,
                    startTimeSunAft: commerceData.startTimeSunAft,
                    endTimeMonAft: commerceData.endTimeMonAft,
                    endTimeTueAft: commerceData.endTimeTueAft,
                    endTimeWedAft: commerceData.endTimeWedAft,
                    endTimeThuAft: commerceData.endTimeThuAft,
                    endTimeFriAft: commerceData.endTimeFriAft,
                    endTimeSatAft: commerceData.endTimeSatAft,
                    endTimeSunAft: commerceData.endTimeSunAft,
                    website: commerceData.website,
                    twitter: commerceData.twitter,
                    instagram: commerceData.instagram,
                    facebook: commerceData.facebook,
                    description: commerceData.description,
                    email: commerceData.email,
                    latlng: coordinates,
                    notifications: commerceData.notifs,
                    phone: commerceData.phone,
                    type: commerceType,
                    displayImage
                })
                navigate('/list-commerces')
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/commerces/back-office`, {
                    id: commerceData.id,
                    address: address,
                    name: commerceData.name,
                    password: password,
                    startTimeMonMor: commerceData.startTimeMonMor,
                    startTimeTueMor: commerceData.startTimeTueMor,
                    startTimeWedMor: commerceData.startTimeWedMor,
                    startTimeThuMor: commerceData.startTimeThuMor,
                    startTimeFriMor: commerceData.startTimeFriMor,
                    startTimeSatMor: commerceData.startTimeSatMor,
                    startTimeSunMor: commerceData.startTimeSunMor,
                    endTimeMonMor: commerceData.endTimeMonMor,
                    endTimeTueMor: commerceData.endTimeTueMor,
                    endTimeWedMor: commerceData.endTimeWedMor,
                    endTimeThuMor: commerceData.endTimeThuMor,
                    endTimeFriMor: commerceData.endTimeFriMor,
                    endTimeSatMor: commerceData.endTimeSatMor,
                    endTimeSunMor: commerceData.endTimeSunMor,
                    startTimeMonAft: commerceData.startTimeMonAft,
                    startTimeTueAft: commerceData.startTimeTueAft,
                    startTimeWedAft: commerceData.startTimeWedAft,
                    startTimeThuAft: commerceData.startTimeThuAft,
                    startTimeFriAft: commerceData.startTimeFriAft,
                    startTimeSatAft: commerceData.startTimeSatAft,
                    startTimeSunAft: commerceData.startTimeSunAft,
                    endTimeMonAft: commerceData.endTimeMonAft,
                    endTimeTueAft: commerceData.endTimeTueAft,
                    endTimeWedAft: commerceData.endTimeWedAft,
                    endTimeThuAft: commerceData.endTimeThuAft,
                    endTimeFriAft: commerceData.endTimeFriAft,
                    endTimeSatAft: commerceData.endTimeSatAft,
                    endTimeSunAft: commerceData.endTimeSunAft,
                    website: commerceData.website,
                    twitter: commerceData.twitter,
                    instagram: commerceData.instagram,
                    facebook: commerceData.facebook,
                    description: commerceData.description,
                    email: commerceData.email,
                    latlng: coordinates,
                    notifications: commerceData.notifs,
                    phone: commerceData.phone,
                    type: commerceType,
                    displayImage
                })
                navigate('/list-commerces')
            } catch (err) {
                console.log(err)
            }
        }
    };

    const [toggleCheckBox, setToggleCheckBox] = useState({
        Mon: commerce.startTimeMonMor === "Fermé",
        Tue: commerce.startTimeTueMor === "Fermé",
        Wed: commerce.startTimeWedMor === "Fermé",
        Thu: commerce.startTimeThuMor === "Fermé",
        Fri: commerce.startTimeFriMor === "Fermé",
        Sat: commerce.startTimeSatMor === "Fermé",
        Sun: commerce.startTimeSunMor === "Fermé"
    });


    const handleSelectMaterial = (e) => {
        setCommerceType(hashMap[e.target.value].type)
        setDisplayImage(hashMap[e.target.value].image)
    }

    console.log('image', image)
    return (
        <div className="container">
            <NavBar />
            {commerce && <div>
                <p className="mt-3 mb-1">Email</p>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={commerceData.email}
                    onChange={(e) => setCommerceData({ ...commerceData, email: e.target.value })} />
                <p className="mt-3 mb-1">Mot de passe</p>
                <input
                    type="text"
                    className="form-control"
                    value={password}
                    placeholder="Mot de passe"
                    onChange={(e) => {
                        setPassword(e.target.value)
                        setPreviousPassword(e.target.value)
                    }} />
                <p className="mt-3 mb-1">Nom du commerce</p>
                <input
                    type="text"
                    className="form-control"
                    value={commerceData.name}
                    placeholder="Nom du commerce"
                    onChange={(e) => setCommerceData({ ...commerceData, name: e.target.value })} />
                <p className="mt-3 mb-1">Description</p>
                <textarea
                    rows="8"
                    class="form-control" id="exampleFormControlTextarea1" placeholder="Description"
                    value={commerceData.description}
                    onChange={(e) => setCommerceData({ ...commerceData, description: e.target.value })} />
                <p className="mt-3 mb-1">Site internet</p>
                <input
                    type="text"
                    className="form-control"
                    value={commerceData.website}
                    placeholder="Site internet"
                    onChange={(e) => setCommerceData({ ...commerceData, website: e.target.value })} />
                <p className="mt-3 mb-1">Instagram</p>
                <input
                    type="text"
                    className="form-control"
                    value={commerceData.instagram}
                    placeholder="Instagram"
                    onChange={(e) => setCommerceData({ ...commerceData, instagram: e.target.value })} />
                <p className="mt-3 mb-1">Facebook</p>
                <input
                    type="text"
                    className="form-control"
                    value={commerceData.facebook}
                    placeholder="Facebook"
                    onChange={(e) => setCommerceData({ ...commerceData, facebook: e.target.value })} />
                <p className="mt-3 mb-1">Twitter</p>
                <input
                    type="text"
                    className="form-control"
                    value={commerceData.twitter}
                    placeholder="Twitter"
                    onChange={(e) => setCommerceData({ ...commerceData, twitter: e.target.value })} />
                <p className="mt-3 mb-1">Adresse</p>
                <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input {...getInputProps({ placeholder: "Entrez une adresse" })} />

                            <div>
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion, index) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? "#41b6e6" : "fff"
                                    }
                                    return (
                                        <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>

                <p>Images</p>
                <div className="container">
                    <div className="row d-flex">
                        {commerce.image.map((image, index) => {
                            return (
                                <ImageChangeCustom
                                    commerceId={commerceData.id}
                                    image={image}
                                    promoImages={image}
                                    index={index}
                                    setPromoImages={setImage}
                                />
                            )
                        })}
                    </div>
                </div>

                <h5>Horaires d'ouvertures</h5>
                <ModifyHoraires
                    day="Lundi"
                    valueCheckbox={toggleCheckBox.Mon}
                    value1={commerceData.startTimeMonMor === "Fermé" ? "" : commerceData.startTimeMonMor}
                    value2={commerceData.endTimeMonMor}
                    value3={commerceData.startTimeMonAft}
                    value4={commerceData.endTimeMonAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeMonMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeMonMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeMonAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeMonAft: e.target.value })}
                    condition={!toggleCheckBox.Mon}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Mon: e.target.checked })
                        if (toggleCheckBox.Mon === false) {
                            setCommerceData({ ...commerceData, startTimeMonMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Mardi"
                    valueCheckbox={toggleCheckBox.Tue}
                    value1={commerceData.startTimeTueMor === "Fermé" ? "" : commerceData.startTimeTueMor}
                    value2={commerceData.endTimeTueMor}
                    value3={commerceData.startTimeTueAft}
                    value4={commerceData.endTimeTueAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeTueMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeTueMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeTueAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeTueAft: e.target.value })}
                    condition={!toggleCheckBox.Tue}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Tue: e.target.checked })
                        if (toggleCheckBox.Tue === false) {
                            setCommerceData({ ...commerceData, startTimeTueMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Mercredi"
                    valueCheckbox={toggleCheckBox.Wed}
                    value1={commerceData.startTimeWedMor === "Fermé" ? "" : commerceData.startTimeWedMor}
                    value2={commerceData.endTimeWedMor}
                    value3={commerceData.startTimeWedAft}
                    value4={commerceData.endTimeWedAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeWedMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeWedMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeWedAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeWedAft: e.target.value })}
                    condition={!toggleCheckBox.Wed}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Wed: e.target.checked })
                        if (toggleCheckBox.Wed === false) {
                            setCommerceData({ ...commerceData, startTimeWedMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Jeudi"
                    valueCheckbox={toggleCheckBox.Thu}
                    value1={commerceData.startTimeThuMor === "Fermé" ? "" : commerceData.startTimeThuMor}
                    value2={commerceData.endTimeThuMor}
                    value3={commerceData.startTimeThuAft}
                    value4={commerceData.endTimeThuAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeThuMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeThuMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeThuAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeThuAft: e.target.value })}
                    condition={!toggleCheckBox.Thu}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Thu: e.target.checked })
                        if (toggleCheckBox.Thu === false) {
                            setCommerceData({ ...commerceData, startTimeThuMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Vendredi"
                    valueCheckbox={toggleCheckBox.Fri}
                    value1={commerceData.startTimeFriMor === "Fermé" ? "" : commerceData.startTimeFriMor}
                    value2={commerceData.endTimeFriMor}
                    value3={commerceData.startTimeFriAft}
                    value4={commerceData.endTimeFriAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeFriMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeFriMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeFriAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeFriAft: e.target.value })}
                    condition={!toggleCheckBox.Fri}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Fri: e.target.checked })
                        if (toggleCheckBox.Fri === false) {
                            setCommerceData({ ...commerceData, startTimeFriMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Samedi"
                    valueCheckbox={toggleCheckBox.Sat}
                    value1={commerceData.startTimeSatMor === "Fermé" ? "" : commerceData.startTimeSatMor}
                    value2={commerceData.endTimeSatMor}
                    value3={commerceData.startTimeSatAft}
                    value4={commerceData.endTimeSatAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeSatMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeSatMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeSatAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeSatAft: e.target.value })}
                    condition={!toggleCheckBox.Sat}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Sat: e.target.checked })
                        if (toggleCheckBox.Sat === false) {
                            setCommerceData({ ...commerceData, startTimeSatMor: 'Fermé' })
                        }
                    }}
                />

                <ModifyHoraires
                    day="Dimanche"
                    valueCheckbox={toggleCheckBox.Sun}
                    value1={commerceData.startTimeSunMor === "Fermé" ? "" : commerceData.startTimeSunMor}
                    value2={commerceData.endTimeSunMor}
                    value3={commerceData.startTimeSunAft}
                    value4={commerceData.endTimeSunAft}
                    onChange1={(e) => setCommerceData({ ...commerceData, startTimeSunMor: e.target.value })}
                    onChange2={(e) => setCommerceData({ ...commerceData, endTimeSunMor: e.target.value })}
                    onChange3={(e) => setCommerceData({ ...commerceData, startTimeSunAft: e.target.value })}
                    onChange4={(e) => setCommerceData({ ...commerceData, endTimeSunAft: e.target.value })}
                    condition={!toggleCheckBox.Sun}
                    onChangeCheckbox={(e) => {
                        setToggleCheckBox({ ...toggleCheckBox, Sun: e.target.checked })
                        if (toggleCheckBox.Sun === false) {
                            setCommerceData({ ...commerceData, startTimeSunMor: 'Fermé' })
                        }
                    }}
                />

                <p>Catégorie</p>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={commerceType}
                    label="Age"
                    onChange={handleSelectMaterial}
                >
                    {Object.entries(hashMap).map((commerce, index) => {
                        return (
                            <MenuItem value={commerce[0]} key={index}>{commerce[1].type}</MenuItem>
                        )
                    })}
                </Select>

                <p>Catégorie : {commerceType}</p>

                <p>Sous-type du Commerce</p>
                <input className="form-control" type="text" defaultValue={commerceData.category} placeholder="Sous-Type" onChange={(e) => setCommerceData({ ...commerceData, category: e.target.value })
                } />

                <p>Téléphone</p>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Téléphone"
                        value={commerceData.phone}
                        onChange={(e) => setCommerceData({ ...commerceData, phone: e.target.value })} />
                </div>

                <div>
                    <p>Quantité de notifs restantes</p>
                    <input
                        type="text"
                        className="form-control"
                        value={commerceData.notifs}
                        onChange={(e) => setCommerceData({ ...commerceData, notifs: e.target.value })} />
                </div>
                <div>
                    <button type="button" className="btn btn-success" onClick={handleUpload}>Soumettre</button>
                </div>
            </div>}
        </div>
    );
};

export default CommerceDetailScreen;
